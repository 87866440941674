import React from "react";
import { Strings } from "../../resources/strings";
import Text from "../atoms/text"
import "../../styles/functionality.scss";

interface Props {
  title: string;
  description: string;
  isNew: boolean;
  image: string;
}

const Functionality: React.FC<Props> = ({ title, description, isNew, image }) => {
  const newClass = isNew ? "new-label" : "new-label-hidden"
  return (
    <div className="content-layout">
      <img className="device" src={image} alt="hola"/>
      <div className="functionality-text">
        <p className={newClass}>{Strings.new}</p>
        <h3>{title}</h3>
        <Text text={description} />
      </div>
    </div>
  );
};

export default Functionality;
