export default class SequentialNavigationController<T> {
  public sections: T[];
  public current: number = 0;

  constructor(sections: T[], current?: T) {
    this.sections = sections;
    if (current) {
      const pos: number = sections.indexOf(current);
      if (pos >= 0) {
        this.current = pos;
      }
    }
  }

  public goBack(): T {
    if (this.current !== 0) {
      this.current--;
    }
    return this.getCurrentSection();
  }

  public goForward(): T {
    if (this.current !== this.sections.length - 1) {
      this.current++;
    }
    return this.getCurrentSection();
  }

  public getCurrentSection(): T {
    return this.sections[this.current];
  }

  public setCurrentSection(section: T): boolean {
    const pos = this.sections.indexOf(section);
    if (pos >= 0) {
      this.current = pos;
      return true;
    } else {
      return false;
    }
  }
}
