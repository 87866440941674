import React from "react";
import { Strings } from "../../resources/strings";
import Text from "../atoms/text";
import "../../styles/contact.scss";
import logo from "../../images/theagilemonkeys_logo.svg"

const Contact: React.FC = () => {
  return (
    <div className="content-layout">
      <div className="contact-header">
        <h1 className="contact-title">{Strings.contactTitle}</h1>
        <Text text={Strings.contactSubtitle} tag="h4"/>
      </div>
      <div className="contact-information">
        <p>{Strings.contactUs}</p>
        <h4><a href={"mailto: " + Strings.email}>{Strings.email}</a></h4>
        <div className="contact-logo-wrapper">
          <p>{Strings.moreAboutUs}</p>
          <a href={Strings.theamWebsiteUrl}>  
            <img className="contact-logo"
              src={logo} 
              alt={Strings.theAgileMonkeysLogo}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact;
