import React from "react";
import { Strings } from "../../resources/strings";
import "../../styles/theory.scss";
import { Section } from "../../models/sections";
import { NavLink } from "react-router-dom";

const Theory: React.FC = () => {
  return (
    <div className="content-layout">
      <div className="theory-text">
        <h2>{Strings.theoryTitle}</h2>
        <h4>{Strings.theorySubtitle}</h4>
        <NavLink className="theory-button" to={Section.Contact}>{Strings.theoryButton}</NavLink>
      </div>
    </div>
  );
};

export default Theory;
