import React from "react";
import { NavLink } from "react-router-dom";
import Link from "../../models/link";
import "../../styles/navbar.scss";

interface Props {
  key: number;
  link: Link;
}

const NavbarItem: React.FC<Props> = ({ link }) => {
  return (
    <li className="navbarItem-li">
      <NavLink className="navbarItem-a" activeClassName="navbarItem-a-active" to={link.path}> {link.name} </NavLink>
    </li>
  );
};

export default NavbarItem;
