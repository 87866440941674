import { throttle } from "lodash";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Navbar from "./components/organisms/navbar";
import About from "./components/sections/about";
import Contact from "./components/sections/contact";
import Functionality from "./components/sections/functionality";
import Theory from "./components/sections/theory";
import Link from "./models/link";
import { Section } from "./models/sections";
import { Strings } from "./resources/strings";
import "./styles/app.scss";
import SequentialNavigationController from "./utils/sequentialNavigationController";
import SectionNumber from "./components/atoms/sectionNumber";
import { ReactComponent as Logo} from "./images/radio_therapp_logo.svg";
import calculatorMockup from "./images/calculator_mockup.png";
import interruptedTreatmentMockup from "./images/interrupted_treatment_mockup.png";
import PrivacyPolicy from "./components/sections/privacyPolicy";


const navLinks: Link[] = [
  {
    name: Strings.introduction,
    path: Section.About
  },
  {
    name: Strings.howItWorks,
    path: Section.HowItWorks
  },
  {
    name: Strings.contact,
    path: Section.Contact
  },
];

const appSections = [
  Section.About,
  Section.Theory,
  Section.Calculator,
  Section.Interruption,
  Section.Contact
];

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Route exact path={Section.Home}>
        <Redirect to={Section.About} />
      </Route>
      <Route exact path={Section.PrivacyPolicy}>
        <PrivacyPolicy />
      </Route>
      <Route path={appSections}>
        <div className="sidebar">
          <Navbar links={navLinks}/>
        </div>
        <div className="title">
          <Logo />
        </div>
        <AppRouter />
      </Route>
    </BrowserRouter>
  );
};

const switchAppTheme: (section: Section) => void = (section) => {
  switch (section) {
    case Section.About:
      document.body.className = "grad-theme";
      break;
    case Section.Contact:
      document.body.className = "dark-theme";
      break;
    case Section.Theory:
      document.body.className = "light-blue-theme";
      break;
    case Section.PrivacyPolicy:
      document.body.className = "dark-theme";
      break;
    default:
      document.body.className = "light-theme";
      break;
  }
};

const getSectionNumber: (section: Section) => number = (section) => {
  switch (section) {
    case Section.About:
    case Section.Home:
      return 1;
    case Section.Contact:
      return 3;
    case Section.PrivacyPolicy:
      return 0;
    default:
      return 2;
  }
}


const AppRouter: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const currentSection = location.pathname as Section;
  const navigationController = useRef(new SequentialNavigationController(appSections, currentSection));
  const [sectionNumber, setSectionNum] = useState(getSectionNumber(currentSection));
  const numberOfSections = 3;
  switchAppTheme(currentSection);

  const setSectionNumber = useCallback(
    (section: Section) => {
      setSectionNum(getSectionNumber(section))
    }, [setSectionNum]
  );

  const navigate = useCallback( 
    (delta: number, inverseDirection: boolean = false) => {
      const currentSection = navigationController.current.getCurrentSection();
      const direction = inverseDirection ? -1 : 1;
      const newSection = direction * delta > 0 ?
        navigationController.current.goForward() :
        navigationController.current.goBack();

      if (currentSection !== newSection) {
        history.push(newSection);
        setSectionNumber(newSection)
      }
    }, [history, navigationController, setSectionNumber]
  );

  const wheelNavigate = useCallback( 
    (e: WheelEvent) => {
      if (Math.abs(e.deltaY) < 10) { return; }
      navigate(e.deltaY);
    }, [navigate]
  );

  let startPosition = useRef(0);
  const startTouchNavigation = useCallback( 
    (e: TouchEvent) => {
      startPosition.current = e.changedTouches[0].pageY;
    }, []
  );

  const endTouchNavigation = useCallback(
    (e: TouchEvent) => {
      const dist = e.changedTouches[0].pageY - startPosition.current;
      if (dist === 0 || Math.abs(dist) < 10) { return; }
      navigate(dist, true);
    }, [navigate]
  );

  const setSectionListener = useCallback(
    () => {
      history.listen((loc) => {
        const section = loc.pathname as Section;
        switchAppTheme(section);
        setSectionNumber(section);
        navigationController.current.setCurrentSection(section);
      });
    }, [history, setSectionNumber]
  );

  useEffect(() => {
    window.addEventListener("wheel", throttle(wheelNavigate, 700));
    window.addEventListener("touchstart", throttle(startTouchNavigation, 700));
    window.addEventListener("touchend", throttle(endTouchNavigation, 700));
    setSectionListener();
  }, [wheelNavigate, startTouchNavigation, endTouchNavigation, setSectionListener]);


  let number = (<div className="sectionNumber">
    <SectionNumber index={sectionNumber} total={numberOfSections} />
  </div>);

  return (
    <TransitionGroup className="content">
      {number}
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={1000}>
        <Switch location={location}>
          <Route path={Section.About}>
            <About />
          </Route>

          <Route exact path={Section.HowItWorks}>
            <Redirect from={Section.HowItWorks}
              to={Section.Theory} />
          </Route>

          <Route path={Section.Theory}>
            <Theory />
          </Route>

          <Route path={Section.Calculator}>
            <Functionality title={Strings.calculatorTitle}
              description={Strings.calculatorDescription}
              isNew={false}
              image={calculatorMockup} />
          </Route>

          <Route path={Section.Interruption}>
            <Functionality title={Strings.interruptionTitle}
              description={Strings.interruptionDescription}
              isNew={false}
              image={interruptedTreatmentMockup} />
          </Route>
          <Route path={Section.Contact}>
            <Contact />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
