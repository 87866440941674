import React from "react";
import { Strings } from "../../resources/strings";
import "../../styles/about.scss";
import ScrollIndicator from "../atoms/scrollIndicator";
import aboutMock from "../../images/about_mock.png"

const About: React.FC = () => {
  return (
    <div className="content-layout">
      <div className="about-text">
        <h1>
          {Strings.aboutTitle}
        </h1>
        <h4 className="about-subtitle">
          {Strings.aboutSubtitle}
        </h4>
        <div className="scroll-indicator-wrapper">
          <ScrollIndicator/>
        </div>
      </div>
      <img src={aboutMock} className="about-image" alt="mock"/>
    </div>
  );
};

export default About;
