import React from "react";
import Link from "../../models/link";
import NavbarItem from "../atoms/navbarItem";

interface Props {
  links: Link[];
}

const Navbar: React.FC<Props> = ({ links }) => {
  return (
    <ul className="navbar-ul">
      {links.map((item, i) => <NavbarItem key={i} link={item} />)}
    </ul>
  );
};

export default Navbar;
