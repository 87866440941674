export enum Strings {
  introduction = "Introducción",
  howItWorks = "¿Cómo funciona?",
  contact = "Contacto",
  title = "Radio_\ntherapp",
  new = "Próximamente",
  theoryTitle = "¿Cómo funciona?",
  theorySubtitle = "Radio_ therapp es capaz de generar posibles tratamientos compensatorios basándose en los tratamientos aplicados y prescritos (dosis por sesión y dosis total) y los coeficientes alpha/beta de los tejidos sano y tumoral. Descubre más sobre cómo funciona.",
  theoryButton = "Descubre más",
  calculatorTitle = "Calculadora de tratamientos aplicados",
  calculatorDescription = "La app es capaz de calcular un nuevo tratamiento conociendo los tratamientos aplicados y prescritos y los coeficientes alpha/beta de los tejidos sano y tumoral.",
  interruptionTitle = "Generador de tratamientos compensatorios debido a interrupción",
  interruptionDescription = "Radio_ therapp puede generar tratamientos compensatorios para aquellos casos en los que se dé una interrupción inesperada, indicándole cuál será la diferencia en la efictividad de la dosis biológica efectiva.",
  aboutTitle = "Radio_ therapp, una herramienta para radio-oncólogos",
  aboutSubtitle = "Descubre la app pensada para ayudar a tomar mejores decisiones ante incidencias en el tratamiento de radioterapia.",
  contactTitle = "¿Quieres saber más?",
  contactSubtitle = "Ponte en contacto con nosotros para recibir más información o probar el funcionamiento de Radio_ therapp.",
  moreAboutUs = "Una iniciativa de",
  theamWebsiteUrl = "https://www.theagilemonkeys.com",
  theamWebsite = "www.theagilemonkeys.com",
  contactUs = "Contacto",
  email = "info@theagilemonkeys.com",
  scrollLabel = "SCROLL",
  theAgileMonkeysLogo = "The Agile Monkeys logo"
}
