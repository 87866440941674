import React from "react";
import "../../styles/scrollIndicator.scss";
import arrowDown from "../../images/arrow-down.svg"

const ScrollIndicator: React.FC = () => {
  return (
    <div className="indicatorContainer">
      <img src={arrowDown} alt="Scroll down" className="arrow" />
      <div className="scroll">SCROLL</div>
    </div>
  );
};

export default ScrollIndicator;
