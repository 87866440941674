export enum Section {
  Home = "/",
  About = "/about",
  HowItWorks = "/how",
  Theory = "/how/theory",
  Calculator = "/how/calculator",
  Interruption = "/how/interruption",
  Contact = "/contact",
  PrivacyPolicy = "/privacy"
}
