import React from 'react';
import "../../styles/sectionNumber.scss"

interface Props {
    index: number,
    total: number
}

const SectionNumber: React.FC<Props> = ({index, total}) => {
    return (
        <div className="sectionNumber">
            <div className="sectionNumber-index">
                {index}
            </div>
            /
            <div className="sectionNumber-total">
                {total}
            </div>
        </div>
    );
}

export default SectionNumber;