import React from 'react';

interface Props {
    text: string
    tag?: keyof JSX.IntrinsicElements;
}

const Text: React.FC<Props> = ({text, tag = "p" }) => {
    const Tag = tag
    return (
        <div>
            {text.split("\n").map((paragraph, key) => 
                <Tag key={key}>{paragraph}</Tag>
            )}
        </div>
    )
}

export default Text;